import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Link from 'gatsby-link'
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContentWrapper from '../styles/contentWrapper'
import OffsetWrapper from '../styles/offsetWrapper'

const NotFoundPage = () => {

  const lostImage = useStaticQuery(graphql`
    query LostImageQuery {
      file (
        name: {
          eq: "maze"
        }
      ) {
        childImageSharp {
          fluid (
            maxWidth: 300
          ) {
            aspectRatio
            src
            srcSet
            sizes
            tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <ContentWrapper>
        <h1>Feeling Lost?</h1>
        <OffsetWrapper>
          <p>It looks like you're not sure where to go. Perhaps you'd like to <Link to={`/blog`}>read an article</Link>?</p>
          <Img 
            alt="person lost in a garden maze"
            fluid={lostImage.file.childImageSharp.fluid}
            />
          <p>Photo by: <a href="https://unsplash.com/@qwitka">Maksym Kaharlytskyi</a></p>
        </OffsetWrapper>
      </ContentWrapper>
    </Layout>
  )
}

export default NotFoundPage
